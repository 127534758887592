@tailwind base;
@tailwind components;
@tailwind utilities;

.Shadow{
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  }

*{
  font-family: 'Lato', sans-serif;
}

